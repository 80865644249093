import * as React from "react"
import Layout from '../templates/page-layout';
import PropertyList from '../components/property-list';
import properties from '../data/properties';


export default () => {
  return (
      <Layout title="Properties for Sale">
          { properties.length > 0 ? PropertyList(properties) : <p>There are currently no properties for sale.</p> }
      </Layout>
  )
}

