import * as React from 'react';
import List from './side-list';

import standardFeatures from '../data/standard-features';

export default (properties) => {
    let items = properties.map((p, ii) => {
        const slug = p.address.toLowerCase().replace(/ /g, '-');
        const url = `../properties/${slug}/`;
        const front = require(`../assets/images/properties/${slug}/front.jpg`);



        let listingClassName = 'label';
        let listingText = p.salePrice;

        if (p.isSold) {
            listingClassName += ' sold';
            listingText = 'Sold';
        } else if (p.salePrice === '0') {
            listingClassName += ' sold';
            listingText = 'Under Contract';
        }



        return (
            <li className="listing-item" key={ii}>
                <a className="title" href={url}>{p.address}</a>
                <span className="excerpt-dash">-</span>
                <span className="excerpt">
                    <div className="row">
                        <div className="col-md-8 label-container">
                            <span className={listingClassName}>{listingText}</span>
                            <img className="img-responsive" src={front} alt={`Elevation for ${p.address}`}/>
                        </div>
                        <div className="col-md-4">
                            {p.lists.map(l => List(l, slug))}
                        </div>
                    </div>
                </span>
            </li>
        )
    });

    return (
        <ul className="display-posts-listing">
            {items}
        </ul>
    );
};